import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import { handleApiResponse } from '../../lib/jsonapi';
import useI18n from '../../lib/use_i18n';
import { signUp } from '../../store';
import { CheckboxField, Form, InputField } from '../common/form';
import { Flex } from '../common/styles/flex';
import { FormRow, RowsWrapper } from '../common/styles/form';
import { StyledButton } from './style';
import { Wrapper } from './wrapper';
import Captcha from './captcha';

export const SignUp = ({
  goHiringUrl,
  privacyPolicyUrl,
  signInUrl,
  conditionsOfUseUrl,
}) => {
  const { translate, generateLink } = useI18n('access');
  const [submitted, setSubmitted] = useState(false);
  const titleKey = submitted ? '.sign_up_success_title' : '.sign_up_title';
  const subtitleKey = submitted ? '.sign_up_success_subtitle' : '.subtitle';

  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const captchaRef = useRef();

  return (
    <Wrapper
      goHiringUrl={goHiringUrl}
      subtitle={translate(subtitleKey)}
      title={translate(titleKey)}
    >
      {submitted ? (
        <a href={signInUrl}>{translate('.back_to_login')}</a>
      ) : (
        <>
          <Form
            onSubmit={async (values) => {
              if (captchaToken === null) return setCaptchaError(true);

              values['captcha_token'] = captchaToken;
              const errors = await handleApiResponse(
                signUp(values),
                ({ status }) => {
                  status === 201 && setSubmitted(true);
                }
              );

              if (errors) captchaRef.current.reset();
              return errors;
            }}
          >
            {({ handleSubmit }) => {
              return (
                <RowsWrapper>
                  <FormRow>
                    <InputField
                      label={translate('.form.first_name.label')}
                      name="first_name"
                      required={true}
                      customMessage={translate('.form.first_name.error')}
                    />
                    <InputField
                      customMessage={translate('.form.last_name.error')}
                      label={translate('.form.last_name.label')}
                      name="last_name"
                      required={true}
                    />
                  </FormRow>
                  <FormRow>
                    <InputField
                      customMessage={translate('.form.email.error')}
                      label={translate('.form.email.label')}
                      name="email"
                      required={true}
                    />
                    <InputField
                      customMessage={translate('.form.company.error')}
                      label={translate('.form.company.label')}
                      name="company"
                      required={true}
                      placeholder={translate('.form.company_placeholder')}
                    />
                  </FormRow>
                  <FormRow>
                    <InputField
                      customMessage={translate('.form.password.error')}
                      label={translate('.form.password.label')}
                      name="password"
                      required={true}
                      type="password"
                    />
                    <InputField
                      customMessage={translate(
                        '.form.password_confirmation.error'
                      )}
                      label={translate('.form.password_confirmation.label')}
                      name="password_confirmation"
                      required={true}
                      type="password"
                      validate={(passwordConfirmation, { password }) =>
                        passwordConfirmation !== password
                          ? translate('.form.password_confirmation.error')
                          : undefined
                      }
                    />
                  </FormRow>
                  <CheckboxField
                    customMessage={translate('.form.agreement.error')}
                    required={true}
                    name="agreement"
                    label={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translate('.form.terms_and_conditions.text', {
                            termsLink: generateLink({
                              href: conditionsOfUseUrl,
                              text: translate(
                                '.form.terms_and_conditions.terms_link'
                              ),
                            }),
                            privacyLink: generateLink({
                              href: privacyPolicyUrl,
                              text: translate(
                                '.form.terms_and_conditions.privacy_link'
                              ),
                            }),
                          }),
                        }}
                      />
                    }
                  />
                  <StyledButton
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    {translate('.form.register_button')}
                  </StyledButton>
                </RowsWrapper>
              );
            }}
          </Form>
          <Flex marginTop="16px" gap="4px">
            <span>{translate('.have_account')}</span>
            <a href={signInUrl}>{translate('.back_to_login')}</a>
          </Flex>{' '}
          <Captcha
            setCaptchaToken={setCaptchaToken}
            ref={captchaRef}
            setCaptchaError={setCaptchaError}
            captchaError={captchaError}
          />
        </>
      )}
    </Wrapper>
  );
};

SignUp.propTypes = {
  termsOfUseUrl: PropTypes.string,
  privacyPolicyUrl: PropTypes.string,
  signInUrl: PropTypes.string,
  setSubmitted: PropTypes.func,
};

SignUp.defaultProps = {
  signInUrl: '/signin',
};
