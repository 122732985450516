/* global process */

import {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  React,
} from 'react';
import PropTypes from 'prop-types';
import { WidgetInstance } from 'friendly-challenge';
import useI18n from '../../lib/use_i18n';
import styled from 'styled-components';

const StyledCaptchaWrapper = styled.div`
  margin-top: 15px;
`;

const StyledCaptcha = styled.div`
  width: 314px;
  ${({ error }) => (error ? 'border: 1px solid #e51c23' : '')}
`;

const Captcha = forwardRef(
  ({ setCaptchaToken, setCaptchaError, captchaError }, ref) => {
    const widgetRef = useRef();
    const container = useRef();
    const { locale, translate } = useI18n('access.form');

    const doneCallback = (solution) => {
      setCaptchaError(false);
      setCaptchaToken(solution);
    };

    const errorCallback = () => {
      setCaptchaToken(null);
      widgetRef.current.reset();
    };

    const reset = () => {
      if (process.env.FRIENDLY_CAPTCHA_MODE == 'test') {
        return setCaptchaToken('test-token');
      }
      setCaptchaToken(null);
      widgetRef.current.reset();
    };

    useImperativeHandle(ref, () => ({
      reset,
    }));

    useEffect(() => {
      if (process.env.FRIENDLY_CAPTCHA_MODE == 'test') {
        return setCaptchaToken('test-token');
      }

      if (!widgetRef.current && container.current) {
        widgetRef.current = new WidgetInstance(container.current, {
          startMode: 'auto',
          doneCallback: doneCallback,
          errorCallback: errorCallback,
          language: locale,
        });
      }

      return () => {
        if (widgetRef.current != undefined) widgetRef.current.reset();
      };
    }, [container]);

    return (
      <StyledCaptchaWrapper className={captchaError ? 'has-error' : ''}>
        <StyledCaptcha error={captchaError}>
          <div
            ref={container}
            className="frc-captcha"
            data-sitekey={process.env.FRIENDLY_CAPTCHA_SITE_KEY}
          />
        </StyledCaptcha>
        <span className="help-block">
          {captchaError ? translate('.captcha_error') : null}
        </span>
      </StyledCaptchaWrapper>
    );
  }
);

Captcha.propTypes = {
  setCaptchaToken: PropTypes.func,
  setCaptchaError: PropTypes.func,
  captchaError: PropTypes.bool,
};

Captcha.displayName = 'Captcha';

export default Captcha;
