import styled from 'styled-components';
import { Flex } from '../common/styles/flex';
import { mobileBreakpoint } from '../common/styles/variables';

export const StyledButton = styled.button`
  width: 100%;
  margin-top: -8px;
`;

export const FormWrapper = styled.div`
  padding: 20px 60px;
`;

export const ImageWrapper = styled.div`
  img {
    width: 136px;
    margin-bottom: 80px;
  }
`;

export const TextWrapper = styled.div`
  h1 {
    font-size: 43px;
    line-height: 56px;
    color: #444;
  }
  div {
    font-size: 18px;
    line-height: 23px;
  }
  margin-bottom: 56px;
`;

const FOOTER_HEIGHT = '37px';
const MOBILE_FOOTER_HEIGHT = '52px';
export const PageWrapper = styled(Flex)`
  height: calc(100vh - ${FOOTER_HEIGHT});
  width: 100vw;
  & > * {
    width: 50%;
  }
  @media (max-width: ${mobileBreakpoint}) {
    flex-direction: column;
    height: calc(100vh - ${MOBILE_FOOTER_HEIGHT});
    & > *:first-child {
      background: white;
    }
    & > *:last-child {
      padding-bottom: 50px;
    }
    & > * {
      width: 100%;
      padding: 10px;
    }
  }
`;
