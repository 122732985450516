import { NoSession } from '../bundles/components/access/no_session';
import { RequestResetPassword } from '../bundles/components/access/request_reset_password';
import { ResetPassword } from '../bundles/components/access/reset_password';
import { SignIn } from '../bundles/components/access/sign_in';
import { SignUp } from '../bundles/components/access/sign_up';
import { topLevel } from '../bundles/hoc';
import '../bundles/react_on_rails';

// This is how react_on_rails can see components in the browser.
window.ReactOnRails.register({
  NoSession: topLevel(NoSession),
  RequestResetPassword: topLevel(RequestResetPassword),
  ResetPassword: topLevel(ResetPassword),
  SignIn: topLevel(SignIn),
  SignUp: topLevel(SignUp),
});
