import styled from 'styled-components';
import useI18n from '../../lib/use_i18n';
import { blue, fontSizeL } from '../common/styles/variables';

const Wrapper = styled.div`
  height: 100%;
  background: ${blue};
  padding: 80px 60px;
  display: flex;
  align-item: center;
  flex-direction: column;
  justify-content: center;
  * {
    color: white;
    text-align: center;
  }
`;

const Quote = styled.div`
  font-size: 21px;
  line-height: 35px;
  padding: 24px 0;
`;

const Name = styled.div`
  font-size: ${fontSizeL};
  line-height: 21px;
`;

const getRandomElement = (array) =>
  array[Math.floor(Math.random() * array.length)];

export const CUSTOMER_QUOTES = [
  {
    translationKey: 'kutter',
    name: 'Veronika Konrad, Personal bei Kutter GmbH & Co. KG Bauunternehmung',
  },
  {
    translationKey: 'asklepios',
    name: 'Katharina Schroeder, Asklepios Kliniken GmbH & Co. KGaA',
  },
  {
    translationKey: 'apetito',
    name: 'Jana Köning, apetito AG',
  },
];

export const CustomerQuote = () => {
  const { translate } = useI18n('customer_quote');
  const { name, translationKey } = getRandomElement(CUSTOMER_QUOTES);

  return (
    <Wrapper>
      <Quote>“{translate(`.${translationKey}`)}”</Quote>
      <Name>{name}</Name>
    </Wrapper>
  );
};
