import PropTypes from 'prop-types';
import Logo from '../../../images/logo_monochrome.svg';
import { CustomerQuote } from './customer_quote';
import { FormWrapper, ImageWrapper, PageWrapper, TextWrapper } from './style';
import Notification from '../common/notification';

export const Wrapper = ({
  children,
  title,
  subtitle,
  goHiringUrl,
  notificationProps,
}) => (
  <PageWrapper>
    <FormWrapper>
      <ImageWrapper>
        <a href={goHiringUrl} rel="noreferrer" target="_blank">
          <img src={Logo} alt="GOhiring logo" />
        </a>
      </ImageWrapper>
      <TextWrapper>
        <h1>{title}</h1>
        <div>{subtitle}</div>
      </TextWrapper>
      {notificationProps?.text && (
        <Notification className="buffer-bottom-2" {...notificationProps} />
      )}
      {children}
    </FormWrapper>
    <CustomerQuote />
  </PageWrapper>
);

Wrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  goHiringUrl: PropTypes.string,
  notificationProps: PropTypes.shape({
    ...Notification.propTypes,
    kind: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
    text: PropTypes.string,
  }),
};

Wrapper.defaultProps = {
  goHiringUrl: 'https://gohiring.com',
};
