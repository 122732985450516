/**
 * Helpers for working with errors returned in the JsonAPI format
 * (https://jsonapi.org/examples/#error-objects-multiple-errors).
 */

export function groupErrorsByFields(errors) {
  return errors.errors.reduce((memo, error) => {
    const field = error.source.pointer.replace('/data/attributes/', '');

    if (!memo[field]) memo[field] = [];

    memo[field].push(error);

    return memo;
  }, {});
}

export function concatErrors(errors) {
  if (!errors) return null;

  return errors.map((error) => error.detail).join(' ');
}

/**
 * If an ajax request fails, an exception might be thrown. In most cases, it should be a response
 * with validation errors. However, it might be different network errors too. So, before accessing
 * validation errors, the response must be checked to avoid needless errors in Rollbar. All errors
 * get processed by the Axios component anyway. So, this method ignores all errors beside
 * validation.
 *
 * Mostly, this function is designed for use in an onSubmit callback of FinalForm, see more there
 * https://final-form.org/docs/react-final-form/types/FormProps#1-synchronous
 *
 * Example:
 *
 *     handleApiResponse(
 *       sendAjaxRequest(),
 *       (resp) => { showSuccessMsg(resp); },
 *       (errors) => { showErrorMsg(errors); }
 *     );
 */
export async function handleApiResponse(
  reqPromise,
  successCallback,
  errorCallback
) {
  errorCallback = errorCallback || (() => {});

  try {
    const res = await reqPromise;
    successCallback(res);
  } catch (error) {
    if (error.response && error.response.data) {
      errorCallback(error.response.data);

      // return validation errors which will be shown by FinalForm
      return error.response.data;
    } else {
      console.error(error);
    }
  }
}
